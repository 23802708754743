import API from '@/services/api'

const state = () => ({
  studentList:[],
  staffList:[]
})

const getters = {
}

const mutations = {
  SET_STAFF_LIST(state, staffList){
    state.staffList = staffList
  },
  SET_STUDENT_LIST(state, studentList){
    state.studentList = studentList
  },
}

const actions = {
  uploadStudentCSV({dispatch, rootState}, {data}){
    data.append('questionType', rootState.questionType.pk)
    return API.uploadStudentCSV(data)
      .then(() => {
        dispatch('fetchStudentList')
      })
  },
  createStudent({dispatch, rootState}, {data}){
    data.questionType = [rootState.questionType.pk]
    return API.createStudent(data)
      .then(() => {
        dispatch('fetchStudentList')
      })
  },
  deleteStudent({dispatch, rootState}, {pk}){
    let questionType = [rootState.questionType.pk]
    return API.deleteStudent(pk, questionType)
      .then(() => {
        dispatch('fetchStudentList')
      })
  },
  updateStudent({dispatch}, {pk, patchObj}){
    return API.updateStudent(pk, patchObj)
      .then(() => {
        dispatch('fetchStudentList')
      })
  },
  updateStaff({dispatch}, {pk, patchObj}){
    return API.updateStaff(pk, patchObj)
      .then(() => {
        dispatch('fetchStaffList')
      })
  },
  createStaff({dispatch, rootState}, {data}){
    data.questionType = [rootState.questionType.pk]
    return API.createStaff(data)
      .then(() => {
        dispatch('fetchStaffList')
      })
  },
  deleteStaff({dispatch}, {pk}){
    return API.deleteStaff(pk)
      .then(() => {
        dispatch('fetchStaffList')
      })
  },
  fetchStaffList({commit, rootState}){
    return API.getStaffList(rootState.questionType.pk)
      .then(({data}) => {
        const staffList = data.map((data)=>{
          let staff = {
            isDashboard: data.user.isDashboard,
            isQuestionManagement: data.user.isQuestionManagement,
            isStaffAccountManagement: data.user.isStaffAccountManagement,
            isStudentAccountManagement: data.user.isStudentAccountManagement,
            account: data.user.account,
            ...data
          }
          return staff
        })
        commit('SET_STAFF_LIST', staffList)
      })
  },
  fetchStudentList({commit, rootState}){
    return API.getStudentList(rootState.questionType.pk)
      .then(({data}) => {
        const studentList = data.map((data)=>{
          let student = {
            account: data.user.account,
            ...data
          }
          return student
        })
        commit('SET_STUDENT_LIST', studentList)
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
