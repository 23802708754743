import Vue from 'vue'
import Vuex from 'vuex'
import API from '@/services/api'
import user from '@/store/modules/user'
import question from '@/store/modules/question'
import practice from '@/store/modules/practice'
import exam from '@/store/modules/exam'
import account from '@/store/modules/account'
import dashboard from '@/store/modules/dashboard'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    questionTypeList:[],
    questionType: null,
  },
  mutations: {
    SET_QUESTION_TYPE_LIST(state, questionTypeList){
      state.questionTypeList = questionTypeList
    },
    SET_QUESTION_TYPE(state, questionType){
      state.questionType = questionType
    }
  },
  actions: {
    getQuestionTypeList({state, commit}){
      return API.getQuestionTypeList()
      .then(({data}) => {
        commit('SET_QUESTION_TYPE_LIST', data)
        // use the first question type as default
        if(!state.questionType){
          commit('SET_QUESTION_TYPE', data[0])
        }
      })
    },
    updateQuestionTypeList({state, commit}){
      return API.getQuestionTypeList()
      .then(({data}) => {
        commit('SET_QUESTION_TYPE_LIST', data)
        // use the first question type as default
        if(!state.questionType){
          commit('SET_QUESTION_TYPE', data[0])
        }else{ // update question type with new data
          const currentTypeId = state.questionType.pk
          const currentIndex = data.findIndex(questionType => questionType.pk === currentTypeId)
          commit('SET_QUESTION_TYPE', data[currentIndex])
        }
      })
    },
  },
  modules: {
    user,
    question,
    practice,
    exam,
    account,
    dashboard,
  },
})
