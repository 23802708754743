import API from '@/services/api'
import lodash from 'lodash'

const state = () => ({
  questionIdList:[],
  questionIndex:0,
  question: null,
  answerOptions:[],
  questionStageMapping:{
    PC:"Practice",
    PE:"Pre-Exam",
    FE:"Final-Exam",
    AR:"Archived",
  },

  // for question management
  questionList:[],
})

const getters = {
  isFinalQuestion: (state) => {
    return state.questionIndex === state.questionIdList.length - 1
  },
  getAnswerOptions: (state) => {
    return state.answerOptions
  },
  listOfQuestionStageCode: (state) => {
    return Object.keys(state.questionStageMapping)
  },
  listOfQuestionStageText: (state, getters) => {
    return getters.listOfQuestionStageCode.map( (key)=>{
      return state.questionStageMapping[key]
    })
  },
}

const mutations = {
  SET_QUESTION_ID_LIST(state, questionIdList){
    state.questionIdList = questionIdList
  },
  SET_QUESTION_INDEX(state, index){
    state.questionIndex = index
  },
  ADD_QUESTION_INDEX(state){
    state.questionIndex++
  },
  RESET_QUESTION_INDEX(state){
    state.questionIndex=0
  },
  SET_QUESTION(state, question){
    state.question = question
  },
  SET_ANSWER_OPTIONS(state, options){
    state.answerOptions = options
  },
  SET_QUESTION_LIST(state, questionList){
    state.questionList = questionList
  },
}

const actions = {
  shuffleQuestionList({commit, state}, seed) {
    let m = state.questionIdList.length, t, i;
    while (m) {
      let x = Math.sin(seed++) * 10000;
      x = x - Math.floor(x);
      i = Math.floor(x * m--);
      t = state.questionIdList[m];
      state.questionIdList[m] = state.questionIdList[i];
      state.questionIdList[i] = t;
      ++seed
    }
    commit('SET_QUESTION_ID_LIST', state.questionIdList)
  },
  addQuestionIndex({commit, getters}){
    if(!getters.isFinalQuestion){
      commit('ADD_QUESTION_INDEX')
    }else{
      //TODO: modify this line after testing, should be return error?
      commit('RESET_QUESTION_INDEX')
    }
  },
  resetQuestionIndex({commit}){
    commit('RESET_QUESTION_INDEX')
  },
  fetchAllQuestion({state, commit, rootState}, payload){
    const queryObj = {
      questionType: rootState.questionType.pk,
      questionStage: payload
        ? payload.questionStage
        : null
    }
    return API.getQuestion(queryObj)
      .then(({data}) => {
        const questionList = data.map((questionData)=>{
          let question = {
            questionId: questionData.pk,
            seqNumber: questionData.seqName,
            name: questionData.name,
            stage: state.questionStageMapping[questionData.questionCategory],
            correctAnswer: questionData.correctAnswer,
            description: questionData.description,
            svgMeta: questionData.svgMeta,
            svgFile: questionData.svgFile,
            filePath: questionData.filePath,
            options: questionData.options,
          }
          return question
        })
        commit('SET_QUESTION_LIST', questionList)
      })
  },
  fetchQuestion({commit, state}){
    const questionId = state.questionIdList[state.questionIndex]
    return API.getQuestion({id:questionId})
      .then(({data}) => {
        // TODO: hard code random desc only for svg graphic 
        let desc = ''
        if (data.svgMeta) {
          desc = data.description? data.description: generateDesc()
        } 
        let question = {
          pk: data.pk,
          svgMeta: data.svgMeta,
          description: desc,
          name: data.name,
          svgFile: data.svgFile,
          filePath: data.filePath,
          coordinates: data.coordinates,
        }
        commit('SET_QUESTION', question)
        // move to the right place later
        commit('SET_ANSWER_OPTIONS', data.options)
      })
  },
  fetchQuestionById({commit}, {questionId, rawDesc}){
    return API.getQuestion({id:questionId})
      .then(({data}) => {
        // TODO: hard code random desc only for svg graphic 
        let desc = ''
        if (data.svgMeta) {
          desc = data.description
            ? data.description
            : rawDesc
              ? 'Random Description'
              : generateDesc()
        } 
        let question = {
          pk: data.pk,
          svgMeta: data.svgMeta,
          description: desc,
          name: data.name,
          svgFile: data.svgFile,
          filePath: data.filePath,
          coordinates: data.coordinates,
        }
        commit('SET_QUESTION', question)
        // move to the right place later
        commit('SET_ANSWER_OPTIONS', data.options)
      })
  },
  clearQuestion({commit}){
    commit('SET_QUESTION', null)
  },
  submitAnswer({state}, {examId, preExamId, practiceId, answer}){
    //questionID, owner, answer
    const answerObject = {
      question: state.question.pk,
      studentAnswer: answer,
    }
    if(practiceId) answerObject['practiceStatus']=practiceId
    if(examId) answerObject['examStatus']=examId
    if(preExamId) answerObject['preExamStatus']=preExamId

    return API.createAnswer(answerObject)
  },
}
function getRandom(min,max){
  return Math.floor(Math.random()*(max-min+1))+min;
}

function generateDesc(){
  const gender = lodash.sample(['man', 'woman'])
  const age = getRandom(40, 80)
  const breastPain = getRandom(1, 5)
  return `A ${gender} aged ${age} years old with ${breastPain} hour(s) chest pain.`
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
