import API from '@/services/api'
import jwt_decode from "jwt-decode";

const state = () => ({
    user:null,
})

const getters = {
    loggedIn(state){
      return !!state.user
    },
}
const actions = {
    login ({ commit }, credentials) {
      return API.login(credentials)
        .then(({ data }) => {
          let userInfo = jwt_decode(data.access)
          commit('SET_USER_DATA', {
            token:data.access,
            account:userInfo
          })
        })
    },
    logout({commit}){
      commit('CLEAR_USER_DATA')
    },

}
const mutations = {
    SET_USER_DATA(state, userData){
      state.user = userData
      localStorage.setItem('user', JSON.stringify(userData))
      API.apiClient.defaults.headers.common['Authorization'] = `Bearer ${userData.token}`
    },
    CLEAR_USER_DATA(){
      localStorage.removeItem('user')
      location.reload()
    },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}