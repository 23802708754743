import API from '@/services/api'
import dayjs from 'dayjs'

const state = () => ({
  finalExamList:[],
  preExamList: [],
  examName: null,
  examConfigId: null,
  finalExamListForManagement:[],
  preExamListForManagement:[],
  examStatusMapping:{
    US:"Unstarted",
    IP:"In Progress",
    FD:"Finished",
  },
})

const actions = {
  fetchPreExamById({commit, dispatch}, examId){
    return API.getPreExam(examId)
      .then(({data}) => {
        commit('SET_EXAM_NAME', `${data.preExamConfig.name}`)
        commit('SET_EXAM_CONFIG_ID', data.preExamConfig.pk)
        commit('question/SET_QUESTION_ID_LIST', data.questions, { root: true })
        //the index of next question is equal to answers.length
        dispatch('question/shuffleQuestionList', this.state.user.user.account.user_info.pk, { root: true })
        commit('question/SET_QUESTION_INDEX', data.answers.length, { root: true })
        // use global dispatch
        return dispatch('question/fetchQuestion', null, { root: true })
      })
  },
  fetchExamById({commit, dispatch}, examId){
    return API.getExam(examId)
      .then(({data}) => {
        commit('SET_EXAM_NAME', `${data.examConfig.name}`)
        commit('SET_EXAM_CONFIG_ID', data.examConfig.pk)
        commit('question/SET_QUESTION_ID_LIST', data.examConfig.questions, { root: true })
        dispatch('question/shuffleQuestionList', this.state.user.user.account.user_info.pk, { root: true })
        //the index of next question is equal to answers.length
        commit('question/SET_QUESTION_INDEX', data.answers.length, { root: true })
        // use global dispatch
        return dispatch('question/fetchQuestion', null, { root: true })
      })
  },
  fetchExamList({commit, rootState}){
    function extractExamList(data, type){
        return data.map(examData=>{
          const totalQuestion = type==='pre-exam'? examData.questions.length: examData.examConfig.questions.length
          const answeredQuestion = examData.answers.length
          const name = type==='pre-exam'? examData.preExamConfig.name : examData.examConfig.name
          const displayScore = type==='pre-exam'
              ? examData.preExamConfig.status==='FD'
              : examData.examConfig.status==='FD'
          const exam = {
            id: examData.pk,
            name,
            totalQuestion,
            answeredQuestion,
            correctAnswers: 'correctAnswerCount' in examData? examData.correctAnswerCount: null,
            prerequisite: examData.accessibleMsg,
            accessible: answeredQuestion === totalQuestion? false : examData.accessible,
            displayScore,
          }
          return exam
        })
    }
    return API.getExamList(rootState.questionType.pk)
      .then(({data})=>{
        const examList = extractExamList(data, 'exam')
        commit('SET_FINALEXAM_LIST', examList)
        return API.getPreExamList(rootState.questionType.pk)
          .then(({data})=>{
            const examList = extractExamList(data, 'pre-exam')
            commit('SET_PREEXAM_LIST', examList)
          })
      })
  },
  fetchExamListForManagement({commit, rootState}){
    function extractExamList(data, type){
        return data.map(examData=>{
          const questionComposition = type ==='pre-exam'
              ? examData.questionComposition.split(',').map((value)=>{return parseInt(value, 10)})
              : examData.questionCompositionOverview.split(',').map((value)=>{return parseInt(value, 10)})
          const totalQuestion = questionComposition.reduce((accumulator, currentValue)=>accumulator + currentValue)
          const exam = {
            id: examData.pk,
            name: examData.name,
            totalQuestion: totalQuestion,
            status: examData.status,
            questionComposition,
            prerequisite: type === 'pre-exam' ? examData.prerequisite : null,
            students: examData.students,
            questions: examData.questions,
            lastUpdate: dayjs(examData.lastUpdated).format("YYYY-MM-DD HH:mm:ss"),
          }
          return exam
        })
    }
    return API.getExamListForManagement(rootState.questionType.pk)
      .then(({data})=>{
        const examList = extractExamList(data, 'exam')
        commit('SET_FINALEXAM_LIST_FOR_MANAGEMENT', examList)
        return API.getPreExamListForManagement(rootState.questionType.pk)
          .then(({data})=>{
            const examList = extractExamList(data, 'pre-exam')
            commit('SET_PREEXAM_LIST_FOR_MANAGEMENT', examList)
          })
      })
  },
}
const mutations = {
  SET_PREEXAM_LIST(state, examList){
    state.preExamList = examList
  },
  SET_FINALEXAM_LIST(state, examList){
    state.finalExamList = examList
  },
  SET_PREEXAM_LIST_FOR_MANAGEMENT(state, examList){
    state.preExamListForManagement = examList
  },
  SET_FINALEXAM_LIST_FOR_MANAGEMENT(state, examList){
    state.finalExamListForManagement = examList
  },
  SET_EXAM_NAME(state, name){
    state.examName = name
  },
  SET_EXAM_CONFIG_ID(state, id){
    state.examConfigId = id
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
