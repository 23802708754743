import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import API from './services/api'

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  created(){
    const userString = localStorage.getItem('user')
    if(userString){
      const userData = JSON.parse(userString)
      this.$store.commit('user/SET_USER_DATA', userData)
    }
    API.apiClient.interceptors.response.use(
      response => response,
      error=>{
        if(error.response.status === 401) {
          this.$store.dispatch('user/logout')
        }
        return Promise.reject(error)
      }
    )
  },
  render: h => h(App)
}).$mount('#app')
