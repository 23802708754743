import API from '@/services/api'

const state = () => ({
  byQuestionData:[],
  byStudentData:[],
  byStudentHeader:[]
})

const getters = {
}

const mutations = {
  SET_BY_QUESTION_DATA(state, byQuestionData){
    state.byQuestionData = byQuestionData
  },
  SET_BY_STUDENT_DATA(state, {data, header}){
    state.byStudentData = data
    state.byStudentHeader = header
  },
}

const actions = {
  fetchByQuestionData({commit, rootState}){
    return API.getDashboardByQuestionData(rootState.questionType.pk)
      .then(({data}) => {
        const questionStageMapping = rootState.question.questionStageMapping
        const questionData = data.map((data)=>{
          let d = {
            ...data,
            questionCategory: questionStageMapping[data.questionCategory],
            rate: `${Math.round(data.rate*10000)/100}%`
          }
          return d
        })
        commit('SET_BY_QUESTION_DATA', questionData)
      })
  },
  fetchByStudentData({commit, rootState}){
    return API.getDashboardByStudentData(rootState.questionType.pk)
      .then(({data}) => {
        let studentHeaderTable = ['account']
        let studentHeader = [{ text: 'Account', value: 'account' }]
        for (let i = 0; i < data.length; i++) {
          for (let k in data[i]['data']) {
            if(!studentHeaderTable.includes(k)) {
              studentHeader.push({text:k, value:k})
              studentHeaderTable.push(k)
            }
          }
        }
        let studentData = data.map((data)=>{
          let d = {
            account: data.account,
          }
          Object.entries(data.data).forEach(([test, score])=>{
            d[test] = Math.round(score*100)/100
          })
          return d
        })
        commit('SET_BY_STUDENT_DATA', {'data': studentData, 'header': studentHeader})
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
