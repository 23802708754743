import Vue from 'vue'
import VueRouter from 'vue-router'
import NotFound from '@/components/NotFound.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name:'home',
    component: () => import('../views/Home.vue'),
    meta: { requriesAuth: true}
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/class',
    name: 'class',
    component: () => import('../views/Class.vue'),
    meta: { requriesAuth: true}
  },
  {
    path: '/edit_class',
    name: 'edit_class',
    component: () => import('../views/EditClass.vue'),
    meta: { requriesAuth: true}
  },
  {
    path: '/practice',
    name: 'practice',
    component: () => import('../views/Practice.vue'),
    meta: { requriesAuth: true}
  },
  {
    path: '/practice/:id',
    name: 'practice_select',
    component: () => import('../views/PracticeSelect.vue'),
    meta: { requriesAuth: true}
  },
  {
    path: '/exam',
    name: 'exam',
    component: () => import('../views/Exam.vue'),
    meta: { requriesAuth: true}
  },
  {
    path: '/exam/:type/:id',
    name: 'exam_select',
    component: () => import('../views/ExamSelect.vue'),
    meta: { requriesAuth: true}
  },
  {
    path: '/edit_question_type',
    name: 'edit_question_type',
    component: () => import('../views/EditQuestionType.vue'),
    meta: { requriesAuth: true}
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('../views/Account.vue'),
    meta: { requriesAuth: true}
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: { requriesAuth: true}
  },
  {
    path: '/question_management',
    name: 'question_management',
    component: () => import('../views/QuestionManagement.vue'),
    meta: { requriesAuth: true}
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
    props: true
  },
  {
    path: '*',
    redirect: {name:'404', params:{resource: 'page'}}
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) =>{
  const loggedIn = localStorage.getItem('user')
  if(to.matched.some(record => record.meta.requriesAuth) && !loggedIn){
    next('/login')
  }
  next()
})

export default router
