<template>
  <v-app-bar app color="primary lighten-1" dark>
    <v-toolbar-title
      v-if="!loggedIn"
    >
      Medical Online Training
    </v-toolbar-title>
    <v-select
      v-else
      id="question-type"
      v-model="questionType"
      :items="questionTypeList"
      item-text="name"
      item-value="pk"
      return-object
      dense
      filled
      hide-details
      single-line
    />
    <v-spacer />
    <v-btn
      v-if="isAdmin"
      text
      rounded
      small
      to="/edit_question_type"
    >
      Question Type
    </v-btn>
    <v-btn
      v-if="isShowClass"
      text
      rounded
      small
      :to="user.account.user_info.is_student ? '/class' : '/edit_class'"
    >
      Class
    </v-btn>
    <v-btn
      v-if="isShowPractice"
      text
      rounded
      small
      to="/practice"
    >
      Practice
    </v-btn>
    <v-btn
      v-if="isShowExam"
      text
      rounded
      small
      to="/exam"
    >
      Exam
    </v-btn>
    <v-btn
      v-if="isShowQuestion"
      text
      rounded
      small
      to="/question_management"
    >
      Question
    </v-btn>
    <v-btn
      v-if="isShowAccount"
      text
      rounded
      small
      to="/account"
    >
      Account
    </v-btn>
    <v-btn
      v-if="isShowDashboard"
      text
      rounded
      small
      to="/dashboard"
    >
      Dashboard
    </v-btn>
    <v-btn
      v-if="!loggedIn"
      text
      rounded
      small
      to="/login"
    >
      Login
    </v-btn>
    <v-btn
      v-else
      text
      rounded
      small
      @click="logout"
    >
      Logout
    </v-btn>
  </v-app-bar>
</template>

<script>
  import {mapState, mapActions, mapGetters} from 'vuex'
  export default {
    name: 'NavBar',
    computed: {
      ...mapState({
        questionTypeList: 'questionTypeList',
        user: state => state.user.user,
      }),
      ...mapGetters({
        loggedIn: 'user/loggedIn'
      }),
      questionType:{
        get () { return this.$store.state.questionType },
        set (value) { this.$store.commit('SET_QUESTION_TYPE', value)}
      },
      isAdmin(){
        return this.loggedIn && this.user.account.user_info.is_admin
      },
      isShowClass(){
        return this.loggedIn && (this.user.account.user_info.is_student || this.user.account.user_info.is_question_management)
      },
      isShowPractice(){
        return this.loggedIn && this.user.account.user_info.is_student
      },
      isShowExam(){
        return this.loggedIn && this.user.account.user_info.is_student
      },
      isShowAccount(){
        return this.loggedIn && ( 
            this.user.account.user_info.is_staff_account_management ||
            this.user.account.user_info.is_student_account_management
          )
      },
      isShowDashboard(){
        return this.loggedIn && this.user.account.user_info.is_dashboard
      },
      isShowQuestion(){
        return this.loggedIn && this.user.account.user_info.is_question_management
      },
    },
    methods:{
      ...mapActions({
        logout: 'user/logout'
      })
    },
  }
</script>

<style>
  input#question-type {
    position: absolute;
    display: inline;
    width: 0;
    height: 0;
    padding: 0;
  }
</style>
