import API from '@/services/api'
import dayjs from 'dayjs'

const state = () => ({
  practiceList:[],
  practiceName: null,
  displayQuestion: null,
  answerHistory:null,
  questionsInBase:[],
  questionSummary:[],
  practiceConfig:null,
})

const actions = {
  fetchPracticeById({commit, dispatch}, practiceId){
    return API.getPractice(practiceId)
      .then(({data}) => {
        commit('SET_PRACTICE_NAME', `Practice ${data.index}`)

        if(data.questions.length == data.answers.length){
        // done, read history
          return dispatch('fetchHistoryByPracticeId', practiceId)
          .then(()=>{
            commit('DISPLAY_HISTORY')
          })
        }else{
        // in progress, answer question
          // use global commit
          commit('question/SET_QUESTION_ID_LIST', data.questions, { root: true })
          dispatch('question/shuffleQuestionList', this.state.user.user.account.user_info.pk, { root: true })
          //the index of next question is equeal to answers.length
          commit('question/SET_QUESTION_INDEX', data.answers.length, { root: true })
          // use global dispatch
          return dispatch('question/fetchQuestion', null, { root: true })
          .then(()=>{
            commit('DISPLAY_QUESTION')
          })
        }
      })
  },
  fetchHistoryByPracticeId({commit, state, dispatch, rootState}, practiceId){
    return API.getHistory(practiceId, rootState.questionType.pk)
    .then(({data}) => {
      const history = data.map((answerRecord)=>{
        const historyRecord = {
          questionId: answerRecord.question.pk,
          userAnswer: answerRecord.studentAnswer,
          correctAnswer: answerRecord.question.correctAnswer,
          createdTime: dayjs(answerRecord.createdAt).format("YYYY-MM-DD HH:mm:ss"),

        }
        return historyRecord
      })
      commit('SET_HISTORY', history)
      dispatch('question/fetchQuestionById', {questionId: state.answerHistory[0].questionId}, { root: true } )
    })
  },
  fetchPracticeList({commit, rootState}){
    return API.getPracticeList(rootState.questionType.pk)
      .then(({data}) => {
        const practiceList = data.map((practiceData)=>{
          let practice = {
            id: practiceData.pk,
            totalQuestion:practiceData.questions.length,
            answeredQuestion: practiceData.answers.length,
            correctAnswers: practiceData.correctAnswerCount,
          }
          return practice
        })
        commit('SET_PRACTICE_LIST', practiceList)
        //TODO: fetch practice config to get total question number
      })
  },
  getPracticeConfig({commit, rootState}){
    return API.getPracticeConfig(rootState.questionType.pk)
      .then(({data})=>{
        commit('SET_PRACTICE_CONFIG', data.length > 0 ? data[data.length-1] : null)
      })

  },
  createPractice({dispatch, state}){
    return dispatch('getPracticeConfig')
      .then(()=>{
        return API.createPractice({practice: state.practiceConfig.pk})
      })
  },
  createPracticeConfig({dispatch, rootState}, questionComposition){
    return API.createPracticeConfig({
      question_type: rootState.questionType.pk,
      questionComposition
    }).then(()=>{
      return dispatch('getPracticeConfig')
    })
  },
  fetchQuestionInBase({commit, rootState}){
    return API.getQuestionBase(rootState.questionType.pk)
    .then(({data}) => {
      const questions = data.map((questionData)=>{
        const  question= {
          questionId: questionData.id,
          correctAnswer: questionData.correctAnswer,
          practiceTimes: questionData.answerCount,
          correctRate: questionData.answerCount? Math.round(questionData.correctCount/questionData.answerCount * 100): null,
        }
        return question
      })
      commit('SET_QUESTIONS_IN_BASE', questions)
    })
    .then(()=>{
      return API.getQuestionSummary(rootState.questionType.pk)
      .then(({data})=>{
        const questionsByAnswer = data.map((questionData)=>{
          const  questionByAnswer = {
            correctAnswer: questionData.correctAnswer,
            practiceTimes: questionData.answerCount,
            correctRate: questionData.answerCount? Math.round(questionData.correctCount/questionData.answerCount * 100): null,
          }
          return questionByAnswer
        })
        commit('SET_QUESTION_SUMMARY', questionsByAnswer)
      })
    })
  },
}
const mutations = {
  SET_HISTORY(state, history){
    state.answerHistory = history
  },
  SET_PRACTICE_LIST(state, practiceList){
    state.practiceList = practiceList
  },
  DISPLAY_QUESTION(state){
    state.displayQuestion = true
  },
  DISPLAY_HISTORY(state){
    state.displayQuestion = false
  },
  SET_PRACTICE_NAME(state, name){
    state.practiceName = name
  },
  SET_QUESTIONS_IN_BASE(state, questionsInBase){
    state.questionsInBase = questionsInBase
  },
  SET_QUESTION_SUMMARY(state, questionSummary){
    state.questionSummary = questionSummary
  },
  SET_PRACTICE_CONFIG(state, practiceConfig){
    state.practiceConfig = practiceConfig
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
