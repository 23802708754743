import axios from 'axios'

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_API_HOST}`,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 10000
})

export default {
  apiClient,
  login(credentials){
    return apiClient.post('/api/token/', credentials)
  },
  // passing questionType to API calls is duplicated,
  // but I think it's better to make it explicitly
  getQuestion({id, questionType, questionStage}) {
    if(id){
      return apiClient.get(`/api/v1/question/${id}/`)
    }
    let query = `/api/v1/question/?question_type=${questionType}`
    if(questionStage) query += `&question_category=${questionStage}`

    return apiClient.get(query)
  },
  getQuestionByOffsetAndLimit(questionType, questionCategory, correctAnswer, offset, limit){
    let url = '/api/v1/question/'
    let query = new URLSearchParams({
      is_pagination: true,
      question_type: questionType || '',
      question_category: questionCategory || '',
      correct_answer:  correctAnswer || '',
      offset: offset || 0 , 
      limit: limit || 10,
    })
    if (query.toString().length) {
      url += '?' + query 
    } else {
      return null
    } 
    return apiClient.get(url)
  },
  createQuestion(data) {
    return apiClient.post('/api/v1/question/', data)
  },
  updateQuestion({id, data}){
    return apiClient.patch(`/api/v1/question/${id}/`, data)
  },
  getQuestionTypeByID(questionTypeID){
    return apiClient.get(`/api/v1/question-type/${questionTypeID}/`)
  },
  getQuestionTypeList(){
    return apiClient.get(`/api/v1/question-type/`)
  },
  createQuestionType(data){
    return apiClient.post(`/api/v1/question-type/`, data)
  },
  updateQuestionType({id, data}){
    return apiClient.put(`/api/v1/question-type/${id}/`, data)
  },
  createAnswer(data) {
    return apiClient.post('/api/v1/answer/', data)
  },
  getHistory(practiceId, questionType) {
    return apiClient.get(`/api/v1/answer-history/?practice_status=${practiceId}&question_type=${questionType}`)
  },
  getPracticeList(questionType) {
    return apiClient.get(`/api/v1/practice-status/?question_type=${questionType}`)
  },
  getPractice(practiceId) {
    return apiClient.get(`/api/v1/practice-status/${practiceId}/`)
  },
  createPractice(data) {
    return apiClient.post(`/api/v1/practice-status/`, data)
  },
  getPracticeConfig(questionType){
    return apiClient.get(`/api/v1/practice/?question_type=${questionType}`)
  },
  createPracticeConfig(data) {
    return apiClient.post(`/api/v1/practice/`, data)
  },
  getQuestionBase(questionType){
    return apiClient.get(`/api/v1/question-based-statistic/?question_type=${questionType}`)
  },
  getQuestionSummary(questionType){
    return apiClient.get(`/api/v1/question-based-statistic/?question_type=${questionType}&by_answer=True`)
  },
  getQuestionCount({questionType, questionStage}){
    return apiClient.get(`/api/v1/question-count-by-correct-answer/?question_type=${questionType}&question_category=${questionStage}`)
  },
  getPreExamList(questionType){
    return apiClient.get(`/api/v1/pre-exam-status/?question_type=${questionType}`)
  },
  getExamList(questionType){
    return apiClient.get(`/api/v1/exam-status/?question_type=${questionType}`)
  },
  getPreExamListForManagement(questionType){
    return apiClient.get(`/api/v1/pre-exam/?question_type=${questionType}`)
  },
  getExamListForManagement(questionType){
    return apiClient.get(`/api/v1/exam/?question_type=${questionType}`)
  },
  getPreExam(examId){
    return apiClient.get(`/api/v1/pre-exam-status/${examId}/`)
  },
  getExam(examId){
    return apiClient.get(`/api/v1/exam-status/${examId}/`)
  },
  createExam({type, data}){
    if(type==='PE') return apiClient.post(`/api/v1/pre-exam/`, data)
    else return apiClient.post(`/api/v1/exam/`, data)
  },
  updateExam({type, id, data}){
    if(type==='PE') return apiClient.patch(`/api/v1/pre-exam/${id}/`, data)
    else return apiClient.patch(`/api/v1/exam/${id}/`, data)
  },
  getStudentList(questionType){
    return apiClient.get(`/api/v1/student/?question_type=${questionType}`)
  },
  getStaffList(questionType){
    return apiClient.get(`/api/v1/staff/?question_type=${questionType}`)
  },
  updateStudent(pk, data) {
    return apiClient.patch(`/api/v1/student/${pk}/`, data)
  },
  createStudent(data) {
    return apiClient.post(`/api/v1/student/`, data)
  },
  deleteStudent(pk, questionType) {
    return apiClient.delete(`/api/v1/student/${pk}/?question_type=${questionType}`)
  },
  uploadStudentCSV(data) {
      return apiClient.post(`/api/v1/student-csv-upload/`, data, {headers: {'Content-Type':'multipart/form-data'}})
  },
  updateStaff(pk, data) {
    return apiClient.patch(`/api/v1/staff/${pk}/`, data)
  },
  createStaff(data) {
    return apiClient.post(`/api/v1/staff/`, data)
  },
  deleteStaff(pk) {
    return apiClient.delete(`/api/v1/staff/${pk}/`)
  },
  getFolderListWithPatient(patientNumber){
    return apiClient.get(`/api/v1/ftp-svg-folder-list/?mr_number=${patientNumber}`)
  },
  getSVGWithPatient({patientNumber, patientRecord}){
    return apiClient.get(`/api/v1/ftp-svg-file-list/?mr_number=${patientNumber}&mr_folder=${patientRecord}`)
  },
  getDashboardByQuestionData(questionType){
    return apiClient.get(`/api/v1/dashboard/by-question/?question_type=${questionType}`)
  },
  getDashboardByStudentData(questionType){
    return apiClient.get(`/api/v1/dashboard/by-student/?question_type=${questionType}`)
  },
}
