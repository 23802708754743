<template>
  <v-footer padless>
    <v-layout justify-center wrap>
      <v-flex
        primary
        lighten-1
        py-4
        text-center
        white--text
        xs12
      >
        {{ new Date().getFullYear() }} — <strong>Medical Online Training</strong>
      </v-flex>
    </v-layout>
  </v-footer>
</template>

<script>
  export default {
    
  }
</script>